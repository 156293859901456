<template>
  <section feature-title-cards v-editable="blok" :data-style="blok.style || 'default'" :id="sectionId">
    <div class="container">
      <div class="row" v-if="[title,description].join('') != ''">
        <label class="section-header" v-if="blok?.section?.length > 0" v-text="blok.section"></label>
        <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
        <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
      </div>
      <div class="row">
        <div class="section-cards">
          <StoryblokComponent v-for="blok in blok.features" :key="blok._uid" :blok="blok" />
        </div>
      </div>
      <div class="row row-footer" v-if="blok.buttons?.length > 0">
        <div class="section-buttons">
          <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/feature-title-cards.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });

const title = computed(() => useRichText(props.blok?.title));
const description = computed(() => useRichText(props.blok?.description));

const sectionId = computed(() => {
    if (props.blok?.section) return textSlugify(props.blok?.section);
    return props.blok._uid;
  });
</script>
